import MultiPageForm from 'forms/MultiPageForm'
import BillingRequestType from 'forms/fieldsets/billing/BillingRequestType'
import PreferredContact from 'forms/fieldsets/general/PreferredContact'

const BillingRequest = () => (
  <MultiPageForm>
    <BillingRequestType path="request-type" />
    <PreferredContact path="preferred-contact" />
  </MultiPageForm>
)

export default BillingRequest