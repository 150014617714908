import InquiryTypeTextArea from 'forms/fields/InquiryTypeTextArea'
import Policies from 'forms/fields/Policies'
import { useTranslation } from 'react-i18next'
import { paymentDateChangesNotAllowed } from 'forms/utils/vars'

const Date = () => {
  const { t } = useTranslation()

return (
  <fieldset>
    <Policies
      name="requested_policies"
      label={t('Which of your policies are you inquiring about?')}
      AllowMultiple
      disabledPolicies={paymentDateChangesNotAllowed}
    />
    <InquiryTypeTextArea
      name="details"
      InquiryType="change_payment_date"
    />
  </fieldset>
)
}

export default Date