import { useContext } from 'react'
import BasePage from 'components/BasePage'
import TenantStr from 'components/TenantStr'
import TenantUrl from 'components/TenantUrl'
import { AppContext } from 'utils/context'
import { useTranslation, Trans } from 'react-i18next'
import './style.scss'

const Contact = () => {

  const { t } = useTranslation()
  const { tenant } = useContext(AppContext)
  
  const hideEmailLink = tenant.hideEmailContactPage
  
  const hasFax = tenant.phone_numbers &&
                 tenant.phone_numbers.default &&
                 tenant.phone_numbers.default.fax
 
  return (
    <BasePage>
      <div className="SitePage">
        <div className="container">
          <h2>{t('Contact Us')}</h2>
          <p>
            <Trans 
              i18nKey="needHelpWithPolicy" 
              components={{productName: <TenantStr ProductName />, s: <strong />}} 
            />
          </p>
          <p>{t("Whatever it is, we're here to help. Contact us by using one of the options below.")}</p>
          <TenantUrl Appointment />
          <address>
            <strong><TenantStr LegalName /></strong><br />
            <TenantStr Hours /><br />
            <TenantStr Address /><br />
            <TenantStr City />, <TenantStr Province /> <TenantStr PostalCode />
          </address>
          <address>
            <strong>{t('Phone:')}</strong> <TenantUrl Phone /><br />
            {hasFax && <span><strong>{t('Fax:')}</strong> <TenantUrl Fax /></span>}
            {!hideEmailLink && (
              <>
                <br />
                <TenantUrl Email />
              </>
            )}
          </address>
        </div>
      </div>
    </BasePage>
  )
}

export default Contact