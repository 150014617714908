import MultiPageForm from 'forms/MultiPageForm'
import RequestType from 'forms/fieldsets/billing/RequestType'
import PreferredContact from 'forms/fieldsets/general/PreferredContact'
import DetailsInput from 'forms/fieldsets/billing/DetailsInput'

const PaymentDetails = () => (
  <MultiPageForm>
    <RequestType path="details" />
    <DetailsInput path="update-details" />
    <PreferredContact path="preferred-contact" />
  </MultiPageForm>
)

export default PaymentDetails