import React, { useEffect, useContext } from 'react'
import Radio from 'forms/fields/Radio'
import Text from 'forms/fields/Text'
import { driversDisable } from 'forms/fieldsets/vehicle/OwnerDriver'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import { PO_OTHER_VALUE } from 'utils/vars'

const Drivers = ({ value, choices, callBack, displayOther=true, ...props }) => {
  
  const { t } = useTranslation()
  const { results }  = useContext(driversDisable)
  const { data, clearAllErrors, setFieldValue } = useContext(FormContext)

  const single = choices.length === 1
  
  const changeCallBack = (val, name, id) => {
    clearAllErrors()
    if(callBack){
      callBack('newCar', val)
    }
    if (val !== 'other'){
      setFieldValue('principal_driver_other', null)
    }
  }
  
  const blurCallBack = (val, name, id) => {
    clearAllErrors()
  }
  
  useEffect(() => {
    if(callBack && data['principal_driver']){
      callBack('newCar', Number(data['principal_driver']))
    }
  }, [typeof results === 'object' && Object.keys(results).length]) //eslint-disable-line
  
  const showOtherPO = data['principal_driver'] !== null && Number(data['principal_driver']) === PO_OTHER_VALUE && displayOther
  
  return (
    <div>
      <Radio
        {...props}
        value={value}
        choices={choices}
        DefaultValue={single ? choices[0][0] : null}
        ChangeCallback={changeCallBack}
        disabledChoices={results ? results['newCar'] : null}
      />
      {showOtherPO && 
        <Text
          name={'principal_driver_other'}
          label={t("What is the full name of the other driver")}
          BlurCallback={blurCallBack}
        />
      }
    </div>
  )
}

export default Drivers