import React, { useContext, useEffect, useState } from 'react'
import { FormContext, AccountContext } from 'utils/context'
import * as Sentry from '@sentry/browser'
import PrincipalDriver from './PrincipalDriver'
import { getAuthedRequest } from 'utils/requests'
import LoadingSpinner from 'components/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import { PO_OTHER_VALUE, PO_ADD_DRIVER_VALUE } from 'utils/vars'

const PrincipalDrivers = ({helpCopy, callBack}) => {
  const { t } = useTranslation()
  const { vehicles, drivers } = useContext(AccountContext)
  const { data, setFieldValue } = useContext(FormContext)
  const [isLoading, setIsLoading] = useState(true)
  
  const isDriverAdd = data['type'] === 'driver_add'

  const driversToShow = drivers.filter(d => d.driver_id !== parseInt(data.driver_id) && data.policy === d.policy)

  let driverChoices = []

  driversToShow.forEach(d => {
    driverChoices.push([d.driver_id, `${d.full_name}`])
  })
  
  if (isDriverAdd) {
    driverChoices.push([PO_ADD_DRIVER_VALUE, `${data['first_name']}  ${data['last_name']}`])
  }

  driverChoices.push([PO_OTHER_VALUE, t('Other')])

  const vehicleName = id =>{
    const singleVehicle = vehicles.find(v => v.vehicle_id === id)
    return singleVehicle ? `${singleVehicle.full_vehicle_name}` : t('No valid vehicle name')
  }
  
  useEffect(() => {
    getAuthedRequest('/api/lineitems/')
      .then(res => {
        const current = res.find(li => li.id === data.id)
        if (current && current.principal_drivers) {
          setFieldValue('principal_drivers', current.principal_drivers)
          setIsLoading(false)
        }
      })
      .catch(err => Sentry.captureException(err))
  }, [data.principal_drivers])  // eslint-disable-line
  
  return (
    <div data-testid="principal-drivers">
      <div>{t('Who would you prefer to be the principal driver?')}</div>
      {data.principal_drivers.length > 0 ? <small className="help-copy">{helpCopy} {t('Please note: If the number of vehicles is equal to or more than the number of drivers on this policy, each driver must be listed as a principal operator of a vehicle.')}</small> : <small className="help-copy">{helpCopy}</small>} 
      
      {isLoading && 
        <div className="LoadingHolder"><LoadingSpinner /></div>}
      
      {!isLoading ? 
      <div data-testid="fetch-success">{
        data.principal_drivers.length > 0 ? 
        data.principal_drivers.filter((driver) => driver.vehicle_id !== Number(data['vehicle_id'])).map(v =>
          <div key={v.id}> 
            <strong className="form-title">{vehicleName(v.vehicle_id)}</strong>
            <PrincipalDriver 
              prefix={v.id}
              defaultValue={v.driver_id}
              defaultValueOther = {v.driver_other}
              driversChoices={driverChoices}
              callBack={callBack}
              vehicleId = {v.vehicle_id}
            />
          </div>
        ) : (data.type !== 'vehicle_replace' ? <strong>{t('This is not applicable to your current policy. Please click continue.')}</strong> : null)
      }</div>
      : null} 
    </div>
  )
}

export default PrincipalDrivers
