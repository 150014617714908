import React, { useContext, useEffect } from 'react'
import { FormContext } from 'utils/context'
import Radio from 'forms/fields/Radio'
import Text from 'forms/fields/Text'
import { driversDisable } from '../vehicle/OwnerDriver'
import { useTranslation } from 'react-i18next'
import { PO_OTHER_VALUE } from 'utils/vars'

const PrincipalDriver = ({prefix, defaultValue, defaultValueOther, driversChoices, callBack, vehicleId}) => {

  const { t } = useTranslation()
  const { data, clearAllErrors, setFieldValue } = useContext(FormContext)
  const { results }  = useContext(driversDisable)
  
  const fieldName = name => prefix ? `${prefix}_${name}` : name

  const changeCallBack = (val, name, id) => {
    clearAllErrors()
    if(callBack){
      callBack(vehicleId, val)
    }
    if (val !== 'other'){
      setFieldValue('principal_driver_other', null)
    }
  }
  
  const blurCallBack = (val, name, id) => {
    clearAllErrors()
  }
  
  useEffect(() => {
    if(callBack && defaultValue){
      callBack(vehicleId, defaultValue)
    }
  }, []) //eslint-disable-line

  const showOtherPO = Number(data[fieldName('driver_id')]) === PO_OTHER_VALUE

  return (
    <fieldset>
      <Radio
        id={fieldName('driver_id')}
        name={fieldName('driver_id')}
        choices={driversChoices}
        DefaultValue={driversChoices.length === 1 ? driversChoices[0][0] : defaultValue}
        ChangeCallback={changeCallBack}
        Stacked
        disabledChoices={results ? results[vehicleId] : null}
      />
      {showOtherPO && 
        <Text
          id={fieldName('driver_other')}
          name={fieldName('driver_other')}
          DefaultValue={defaultValueOther}
          label={t("What is the full name of the other driver")}
          BlurCallback={blurCallBack}
        />
      }
    </fieldset>
  )
}

export default PrincipalDriver
