import React, { useState, useEffect, useContext } from 'react';
import Button from 'components/Buttons/Button';
import { useTranslation } from 'react-i18next'
import { AccountContext } from 'utils/context'
import './style.scss';

const RestartModal = ({handleExpanded}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);
  const { lineitem, deleteLineitem } = useContext(AccountContext)

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
        handleExpanded(false)
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen]); //eslint-disable-line

  const handleCancel = () => {
    setIsOpen(false);
    handleExpanded(false)
  };

  const handleRestart = () => {
    deleteLineitem(lineitem.id)
    setIsOpen(false);
    handleExpanded(false)
  };


  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setIsOpen(false);
      handleExpanded(false)
    }
  };

  return (
    <>
      <Button Pill Light onClick={() => setIsOpen(true)}>
        {t('Delete and restart')}
      </Button>

      {isOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-container">
            <div className="modal-text">
              <span>
                {t('Please confirm that you would like to delete this change request and start from the beginning.')}
              </span>
            </div>
            
            <div className="modal-buttons">
              <Button
                onClick={handleCancel}
                Pill
                Light
              >
                {t('Cancel')}
              </Button>
              <Button
                onClick={handleRestart}
                Pill
              >
                {t('Restart')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RestartModal;