import i18n from 'i18n';

export const numberToIterable = number => {
  if (number && Number(number) > 0) {
    let arr = Array(Number(number)).fill(0)
    return arr
  }
  return []
}

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export const hasFormError = errors => {
  return errors.non_field_errors
}

export const hasFieldError = (keys, errors) => {
  return keys.filter(field => errors[field]).length > 0
}

export const numberToString = number => {
  switch (number) {
    case 1:
    case '1':
      return i18n.t('first')
    case 2:
    case '2':
      return i18n.t('second')
    case 3:
    case '3':
      return i18n.t('third')
    case 4:
    case '4':
      return i18n.t('fourth')
    case 5:
    case '5':
      return i18n.t('fifth')
    case 6:
    case '6':
      return i18n.t('sixth')
    default:
      return null
  }
}

export const noneToFive = [
  ['0', 'None'],
  [1, '1'],
  [2, '2'],
  [3, '3'],
  [4, '4'],
  [5, '5'],
]



export const requiredIf = (type, condition) => {
  return function(props, propName, componentName) {
    if (typeof type !== 'function') {
      return new Error(
        'Invalid react-required-if prop type supplied to ' +
          componentName +
          '. Validation failed.'
      )
    }

    if (typeof condition !== 'function') {
      return new Error(
        'Invalid react-required-if condition supplied to ' +
          componentName +
          '. Validation failed.'
      )
    }

    var test = condition(props) ? type.isRequired : type
    return test.apply(this, arguments)
  }
}

export const carrierTranslation = carrierCode => {
  switch (carrierCode) {
    case 'PEM':
      return 'Pembridge Insurance'
    case 'PAF':
      return 'PAFCO Insurance'
    case 'GA':
      return 'Aviva Insurance'
    case 'GA2':
      return 'Aviva Insurance'
    case 'TRG':
      return 'Aviva Traders'
    case 'TRG2':
      return 'Aviva Traders'
    default:
      return 'Invalid carrier'
  }
}

export const provinceChoices = [
  ['AB', i18n.t('Alberta')],
  ['BC', i18n.t('British Columbia')],
  ['MB', i18n.t('Manitoba')],
  ['NB', i18n.t('New Brunswick')],
  ['NL', i18n.t('Newfoundland and Labrador')],
  ['NT', i18n.t('Northwest Territories')],
  ['NS', i18n.t('Nova Scotia')],
  ['NU', i18n.t('Nunavut')],
  ['ON', i18n.t('Ontario')],
  ['PE', i18n.t('Prince Edward Island')],
  ['QC', i18n.t('Quebec')],
  ['SK', i18n.t('Saskatchewan')],
  ['YT', i18n.t('Yukon')],
]

export const noLiabilityInsuranceDuration = {
  'GA': {
    'ON': 12,
    'NS': 12,
    'PE': 12,
    'NT': 12,
    'NB': 24,
    'NL': 24
  },
  'SR': {
    'ON': 12,
    'NS': 12,
    'PE': 12,
    'NT': 12,
    'NB': 24,
    'NL': 24
  },
  'SAY': {
    'ON': 12,
    'NS': 12,
    'PE': 12,
    'NT': 12,
    'NB': 24,
    'NL': 24
  },
  'TRG': {
    'ON': 12,
    'NS': 12,
    'PE': 12,
    'NT': 12,
    'NB': 24,
    'NL': 24
  },
  'TRG2': {
    'ON': 12,
    'NS': 12,
    'PE': 12,
    'NT': 12,
    'NB': 24,
    'NL': 24
  }
}

// used on inova contact pages

export const regionChoices = {
  AB: 'alberta',
  BC: 'british-columbia',
  MB: 'manitoba',
  NB: 'atlantic',
  NL: 'atlantic',
  NS: 'atlantic',
  ON: 'ontario',
  PE: 'atlantic',
  SK: 'saskatchewan',
}

export const conditionChoices = [
  ['new', i18n.t('New')],
  ['used', i18n.t('Used')],
  ['demo', i18n.t('Demo')],
]

export const purchaseChoices = [
  ['leased', i18n.t('This vehicle is leased')],
  ['financed', i18n.t('This vehicle is financed')],
  ['neither', i18n.t('No, this vehicle is neither leased nor financed')],
]

export const standard = ['TP', 'TPBI', 'TPDC', 'TPPD', 'UA', 'AB', '44', '44R']
export const optional = ['AP', 'CMP', 'COL', 'SP']
export const accident = ['ACB', 'CHHMB', 'WIB', 'DCB', 'DFB', 'HL', 'IDB', 'MEDRH', 'DFP', '34']
export const endorsement = ['20', '20A', '27', 'ACCW', '39', 'CPP', '43', '43A', '43D', '43E', '430', '43R', '43RL', 'MCP', '5L', '5', '26', '6A', '9', '32', '35', 'GLABR', 'GLDED', '2', '3', '8', '16', '17', '18', '19', '25', '28', '30', '37', '38', '40', '47', '48', '13C', '13D', '13H', '19A', '23A', '28A', '8A', 'SRAP']

export const coverageCopy = {
  'AB': {
    desc: i18n.t('Accident Benefits'),
    help:
     i18n.t( 'Accident Benefits provides coverage of payment for income replacement, medical payments, and other financial assistance if you are injured in an auto accident.'),
  },
  'ACB': { desc: i18n.t('Attendant Care Benefit'), help: '' },
  'ACCW': {
    desc: i18n.t('Accident Waiver'),
    help:
      i18n.t('In the event of you being in an accident in which you are at fault, it will help protect your driving record and forgive the accident.'),
  },
  'GLABR': { desc: i18n.t('Glass Breakage'), help: '' },
  'GLDED': { desc: i18n.t('Glass Deductible Endorsement'), help: '' },
  'MCP': {
    desc: i18n.t('Minor Conviction Protection'),
    help:
      i18n.t('In the event of your first Minor Conviction, this endorsement will prevent you from losing your Conviction Free discount.'),
  },
  'AP': {
    desc: i18n.t('All Perils'),
    help: i18n.t('All Perils is a combination of Collision and Comprehensive coverage. Comprehensive covers you for such things as fire, theft, vandalism, and glass breakage. Collision covers the cost to repair or replace your vehicle if it’s damaged in an accident with another vehicle or object.  The deductible is the amount of money that you will pay out of your own pocket before the insurance company responds.'),
  },
  'SP': { desc: i18n.t('Specified Perils'), help: '' },
  'CHHMB': {
    desc: i18n.t('Caregiver, Housekeeping & Home Maintenance Benefit'),
    help: '',
  },
  'CMP': {
    desc: i18n.t('Comprehensive'),
    help:
      i18n.t('Comprehensive covers you for such things as fire, theft, vandalism, and glass breakage. The deductible is the amount of money that you will pay out of your own pocket before the insurance company responds.'),
  },
  'COL': {
    desc: i18n.t('Collision or Upset'),
    help:
      i18n.t('Collision or Upset covers the cost to repair or replace your vehicle if it’s damaged in an accident with another vehicle or object.'),
  },
  'CPP': {
    desc: i18n.t('Claims Protection Plan'),
    help:
      i18n.t('In the event of you being in an accident in which you are at fault, it will help protect your driving record and forgive the accident.'),
  },
  'DCB': { desc: i18n.t('Dependant Care Benefits'), help: '' },
  'DFB': { desc: i18n.t('Death and Funeral Benefits'), help: '' },
  'HL': { desc: i18n.t('Hospital Levy'), help: '' },
  'IDB': { desc: i18n.t('Indexation Benefit (Consumer Price Index)'), help: '' },
  'MEDRH': { desc: i18n.t('Medical and Rehabilitation Benefits'), help: '' },
  'SRAP': {
    desc: i18n.t('Enhanced Roadside Assistance Program'),
    help: '',
  },
  'TP': {
    desc: i18n.t('Third Party Liability'),
    help:
      i18n.t("Third Party Liability covers costs resulting from injuries to a person or damage to someone else's property with your car."),
  },
  'TPBI': {
    desc: i18n.t('Third Party Liability - Bodily Injury'),
    help:
      i18n.t("Third Party Liability - Bodily Injury can cover costs resulting from injuries to a person. These injuries' costs could include expenses like hospital care, lost wages, and pain and suffering due to the accident."),
  },
  'TPDC': {
    desc: i18n.t('Third Party Liability - Direct Compensation'),
    help:
      i18n.t('Third Party Liability - Direct Compensation is coverage to repair your vehicle and provide a rental vehicle while yours is being repaired because of a not-at-fault accident.'),
  },
  'TPPD': {
    desc: i18n.t('Third Party Liability - Property Damage'),
    help:
      i18n.t("Third Party Liability - Property Damage can cover costs resulting from damage to someone else's property with your car."),
  },
  'UA': {
    desc: i18n.t('Uninsured Automobile'),
    help:
      i18n.t('Uninsured Auto can provide coverage if you are injured or your vehicle is damaged by an uninsured or unidentified vehicle.'),
  },
  'WIB': { desc: i18n.t('Income Replacement Benefit'), help: '' },
  '2': {
    desc:
      i18n.t('Providing Coverage When Named Persons Drive Other Automobiles or Rent or Lease Other Automobiles'),
    help:
      i18n.t('An Owner\'s policy covering a private passenger automobile may be extended to provide Liability Coverage, Accident Benefits, and Uninsured Automobile, for any person(s) named in this change form, personally driving any "Other Automobiles.'),
  },
  '3': {
    desc: i18n.t('Permission to Drive Government Vehicles'),
    help:
      i18n.t("This change form is used on an Owner's Policy when the insured also uses a Government owned automobile and the insured is unprotected by insurance coverage."),
  },
  '5': { desc: i18n.t('Permission to Rent or Lease'), help: '' },
  '8': { desc: i18n.t('Property Damage Reimbursement'), help: '' },
  '9': {
    desc: i18n.t('Marine Use Excluded'),
    help: '',
  },
  '16': {
    desc: i18n.t('Agreement for Suspension of Coverage'),
    help:
      i18n.t('Cancels coverage for the use or operation of this vehicle, a newly acquired vehicle or temporary substitute until coverage is reinstated.  This vehicle will be continuously taken out of use and not operated.  In order to use this vehicle for any reason you must first speak with one of our insurance brokers to reinstate the required coverage. The coverage on your vehicle is limited to: Fire, Theft, Vandalism, Glass breakage and some accident benefits, while your vehicle is parked. Please also note that you do not have any coverage for hit and run.'),
  },
  '17': {
    desc: i18n.t('Reinstatement of Coverage'),
    help: '',
  },
  '18': {
    desc: i18n.t('Replacement Cost Endorsement'),
    help: '',
  },
  '19': {
    desc: i18n.t('Limitation of Amount Paid for Loss or Damage Coverage'),
    help: '',
  },
  '20': {
    desc: i18n.t('Loss of Use Coverage'),
    help:
      i18n.t('If your vehicle is in the shop due to an insured claim, you can be reimbursed up to your limit for a rental car, taxi cab, or whatever transportation you need to get around.'),
  },
  '25': {
    desc: i18n.t('Alteration'),
    help: '',
  },
  '26': {
    desc: i18n.t('Disappearing Deductible (All Perils of Collision)'),
    help:
      i18n.t('Where available, this endorsement allows for a reduced deductible typically by a set amount of the original deductible amount, for each consecutive claim free year.'),
  },
  '27': {
    desc: i18n.t('Legal Liability for Damage to Non-Owned Automobile'),
    help:
      i18n.t('If you rent a vehicle anywhere in Canada or the U.S., you do not need to purchase coverage for that rental vehicle, this policy can provide coverage for any damage up to your limit.'),
  },
  '28': {
    desc: i18n.t('Reduction of Coverage for Named Persons'),
    help: '',
  },
  '30': {
    desc: i18n.t('Excluding Operation of Attached Machinery'),
    help: '',
  },
  '32': {
    desc: i18n.t('Motorized Recreational Vehicle'),
    help: '',
  },
  'DFP': {
    desc: i18n.t('Death and Funeral Income Replacement'),
    help: '',
  },
  '34': {
    desc: i18n.t('Injured Persons - Accident Benefits'),
    help: '',
  },
  '35': {
    desc: i18n.t('Emergency Service Expense'),
    help:
      i18n.t('Provides reimbursement for towing and emergency service expenses with limits set out by your insurance company'),
  },
  '37': {
    desc: i18n.t('Limitation for Automobile Electronic Accessories'),
    help: '',
  },
  '38': {
    desc: i18n.t('Agreed Limit for Automobile Electronic Accessories'),
    help:
      i18n.t('This change form limits theft coverage for electronic accessories, other than factory installed, to the actual cash value up to $1,500 in total, unless the equipment is listed, in which case the amount paid will be the limit shown or the actual cash value, whichever is the lesser.'),
  },
  '40': {
    desc: i18n.t('Fire and Theft Deductible'),
    help: '',
  },
  '43': {
    desc: i18n.t('Limited Waiver of Depreciation'),
    help:
      i18n.t('In case you ever get into an accident which causes the full loss of your vehicle, you can be reimbursed for the purchase price, not the depreciated value of the vehicle.'),
  },
  '44': {
    desc: i18n.t('Family Protection'),
    help:
      i18n.t("Family Protection can ensure you have access to your Third Party Liability amount of insurance if you or your family is injured in a not-at-fault accident with an underinsured, uninsured or unidentified motorist."),
  },
  '39': {
    desc: i18n.t('Accident Waiver'),
    help: '',
  },
  '47': {
    desc: i18n.t('Agreement Not to Rely On SABS Priority of Payment'),
    help: '',
  },
  '48': {
    desc: i18n.t('Optional Supplementary Benefits'),
    help: '',
  },
  '13C': {
    desc: i18n.t('Restricting Glass Coverage'),
    help:
      i18n.t('This endorsement can delete glass & windshield damage unless it is covered by a specific peril.'),
  },
  '13D': {
    desc: i18n.t('Limited Glass Coverage'),
    help:
      i18n.t('This endorsement can restrict glass coverage to Specified Perils plus vandalism to all windows except the front.'),
  },
  '13H': {
    desc: i18n.t('Deletion Of Hail Coverage'),
    help:
      i18n.t('This can remove hail damage coverage for vehicles that have existing hail damage.'),
  },
  '19A': {
    desc: i18n.t('Agreed Valued of Automobile(s)'),
    help: '',
  },
  '20A': {
    desc: i18n.t('Loss of Use Coverage'),
    help:
      i18n.t('If your vehicle is in the shop due to an insured claim, you can be reimbursed up to your limit for a rental car, taxi cab, or whatever transportation you need to get around.'),
  },
  '23A': {
    desc: i18n.t('Lienholder Protection'),
    help: i18n.t('Protects the financial interest of the lienholder. If your vehicle is not repaired or replaced due to an insured claim, reimbursement is made jointly to the lienholder and the named insured. It also provides the lienholder with 15 days notice in the event of a mid-term policy cancellation.'),
  },
  '44R': {
    desc: i18n.t('Family Protection'),
    help:
      i18n.t("Family Protection can ensure you have access to your Third Party Liability amount of insurance if you or your family is injured in a not-at-fault accident with an underinsured, uninsured or unidentified motorist."),
  },
  '28A': {
    desc: i18n.t('Excluded Driver'),
    help:
      i18n.t('The driver specified on the form will have no insurance coverage under this policy, except for very limited Accident Benefits, if they drive the described automobile.'),
  },
  '43A': {
    desc: i18n.t('Limited Waiver of Depreciation'),
    help:
      i18n.t('In case you ever get into an accident which causes the full loss of your vehicle, you can be reimbursed for the purchase price, not the depreciated value of the vehicle.'),
  },
  '43D': {
    desc: i18n.t('Limited Waiver of Depreciation'),
    help:
      i18n.t('In case you ever get into an accident which causes the full loss of your vehicle, you can be reimbursed for the purchase price, not the depreciated value of the vehicle.'),
  },
  '43E': {
    desc: i18n.t('Limited Waiver of Depreciation'),
    help:
      i18n.t('In case you ever get into an accident which causes the full loss of your vehicle, you can be reimbursed for the purchase price, not the depreciated value of the vehicle.'),
  },
  '430': {
    desc: 'Replacement Cost',
    help:
      i18n.t('In case you ever get into an accident which causes the full loss of your vehicle, you can be reimbursed for the purchase price, not the depreciated value of the vehicle.'),
  },
  '43R': {
    desc: i18n.t('Limited Waiver of Depreciation'),
    help:
      i18n.t('In case you ever get into an accident which causes the full loss of your vehicle, you can be reimbursed for the purchase price, not the depreciated value of the vehicle.'),
  },
  '43RL': {
    desc: i18n.t('Limited Waiver of Depreciation'),
    help:
      i18n.t('In case you ever get into an accident which causes the full loss of your vehicle, you can be reimbursed for the purchase price, not the depreciated value of the vehicle.'),
  },
  '5L': {
    desc: i18n.t('Permission to Rent or Lease'),
    help: '',
  },
  '6A': {
    desc: i18n.t('Permission to Carry Passengers for Compensation'),
    help:
      i18n.t('This is an endorsement form that removes the limitation found in insurance policies when carryiong passengers for compensation.'),
  },
  '8A': {
    desc: i18n.t('Property Damage Reimbursement'),
    help: '',
  },
}

export const coveragePackages = {
  'PAK01': ['20', '35', '43'],
  'PAK02': ['27', '35', '43'],
  'PAK03': ['20', '43'],
  'PAK04': ['20', '35'],
  'PAK05': ['20', '27', '43'],
  'PAK06': ['20', '27'],
  'PAK07': ['20', '27', '34'],
  'PAK08': ['20', '27', '35', '43'],
  'PAK09': ['20', '27', '35'],
  'PAK10': ['2', '20', '43'],
  'PAK11': ['2', '20A', '27', '34'],
  'PAK12': ['2', '20', '27', '34'],
  'PAK13': ['2', '20', '27'],
  'PAK14': ['2', '20', '27', '43'],
  'PAK15': ['18', '20', '27', '35'],
  'PAK16': ['SRAP', '20', '27', '43'],
  'PAK17': ['SRAP', '20', '27'],
} 

export const driverHistoryAdditionalQuestionsProvinces = new Set(["AB", "NL", "PE", "NS", "NB"])

export const parkedAtNightCarrierCodes = new Set(["PEM", "PAF"])

// requests

export const allRequests = [
  "billing_request",
  "vehicle_add",
  "vehicle_replace",
  "vehicle_remove",
  "driver_remove",
  "driver_add",
  "address_change",
  "policy_cancel",
  "policy_renew",
  "general_request",
]

export const requestMeta = {
  billing_request: {
    description: i18n.t('Update or request payment details'),
    past_tense: i18n.t('Asked a billing question'),
    slug: 'billing-request',
    starting_fieldset: 'request-type',
    start_immediately: true,
    fieldsets: [
      'request-type',
      'preferred-contact',
    ],
  },
  vehicle_remove: {
    description: i18n.t('Remove a vehicle'),
    past_tense: i18n.t('Removed a Vehicle'),
    slug: 'vehicle-remove',
    starting_fieldset: 'get-started',
    start_immediately: false,
    fieldsets: [
      'get-started',
      'remove-reason',
      'other-drivers',
      'confirm',
    ],
  },
  vehicle_add: {
    description: i18n.t('Add a vehicle'),
    past_tense: i18n.t('Added a Vehicle'),
    slug: 'vehicle-add',
    starting_fieldset: 'get-started',
    start_immediately: false,
    fieldsets: [
      'get-started',
      'vehicle-lookup',
      'owner-and-driver',
      'vehicle-details',
      'vehicle-usage',
      'eligibility',
      'coverage',
      'financing-details',
      'other-drivers',
      'confirm',
    ],
  },
  vehicle_replace: {
    description: i18n.t('Replace a vehicle'),
    past_tense: i18n.t('Replaced a Vehicle'),
    slug: 'vehicle-replace',
    starting_fieldset: 'get-started',
    start_immediately: false,
    fieldsets: [
      'get-started',
      'remove-reason',
      'vehicle-lookup',
      'owner-and-driver',
      'vehicle-details',
      'vehicle-usage',
      'eligibility',
      'coverage',
      'financing-details',
      'other-drivers',
      'confirm',
    ],
  },
  driver_remove: {
    description: i18n.t('Remove a driver'),
    past_tense: i18n.t('Removed a Driver'),
    slug: 'driver-remove',
    starting_fieldset: 'get-started',
    start_immediately: false,
    fieldsets: [
      'get-started',
      'remove-reason',
      'other-drivers',
      'confirm',
    ],
  },
  driver_add: {
    description: i18n.t('Add a driver'),
    past_tense: i18n.t('Added a Driver'),
    slug: 'driver-add',
    starting_fieldset: 'get-started',
    start_immediately: false,
    fieldsets: [
      'get-started',
      'driver-information',
      'driver-licensing',
      'driver-history',
      'other-drivers',
      'consent',
      'confirm',
    ],
  },
  address_change: {
    description: i18n.t('Change my address'),
    past_tense: i18n.t('Changed Address'),
    slug: 'address-change',
    starting_fieldset: 'get-started',
    start_immediately: false,
    fieldsets: [
      'get-started',
      'address-lookup',
      'policy-changes',
      'other-drivers',
      'confirm',
    ],
  },
  policy_cancel: {
    description: i18n.t('Cancel my Policy'),
    past_tense: i18n.t('Cancelled my Policy'),
    slug: 'policy-cancel',
    starting_fieldset: 'get-started',
    start_immediately: false,
    fieldsets: [
      'get-started',
      'request-details',
      'request-quote',
      'confirm',
    ],
  },
  policy_renew: {
    description: i18n.t('Review my Renewal'),
    past_tense: i18n.t('Reviewed my Renewal'),
    slug: 'policy-renew',
    starting_fieldset: 'request-details',
    start_immediately: true,
    fieldsets: [
      'request-details',
      'discount-review',
      'confirm',
    ],
  },
  general_request: {
    description: i18n.t('Other / Ask a question'),
    past_tense: i18n.t('Asked a general question'),
    slug: 'other-request',
    starting_fieldset: 'request-details',
    start_immediately: true,
    fieldsets: [
      'request-details',
      'preferred-contact',
    ],
  },
}

export const requestTypes = {
  // questions
  billing_request: {
    label: i18n.t('Billing Question'),
    past_tense: i18n.t('Asked a billing question'),
  },
  general_question: {
    label: i18n.t('Other'),
    past_tense: i18n.t('Asked a general question'),
  },
  general_request: {
    label: i18n.t('Other'),
    past_tense: i18n.t('Asked a general question'),
  },
  payment_schedule: {
    label: i18n.t('Request my Payment Schedule'),
    past_tense: i18n.t('Requested my payment schedule'),
  },
  policy_renew: {
    label: i18n.t('Review my Renewal'),
    past_tense: i18n.t('Reviewed my Renewal'),
  },
  // quotes
  vehicle_add_quote: {
    label: i18n.t('Quote for a New Vehicle'),
    past_tense: i18n.t('Requested a quote for a new vehicle'),
  },
  vehicle_replace_quote: {
    label: i18n.t('Quote for a Replacement Vehicle'),
    past_tense: i18n.t('Requested a quote for a vehicle replacement'),
  },
  general_quote: {
    label: i18n.t('Other'),
    past_tense: i18n.t('Asked a general question'),
  },
  // changes
  vehicle_add: {
    label: i18n.t('Add a Vehicle'),
    past_tense: i18n.t('Added a vehicle'),
  },
  vehicle_replace: {
    label: i18n.t('Replace a Vehicle'),
    past_tense: i18n.t('Replaced a vehicle'),
  },
  vehicle_remove: {
    label: i18n.t('Removed a Vehicle'),
    past_tense: i18n.t('Removed a vehicle'),
  },
  driver_add: {
    label: i18n.t('Add a Driver'),
    past_tense: i18n.t('Added a Driver'),
  },
  driver_remove: {
    label: i18n.t('Remove a Driver'),
    past_tense: i18n.t('Removed a driver'),
  },
  address_change: {
    label: i18n.t('Change my Address'),
    past_tense: i18n.t('Changed my address'),
  },
  payment_date: {
    label: i18n.t('Update my Payment Date'),
    past_tense: i18n.t('Updated my payment date'),
  },
  payment_details: {
    label: i18n.t('Update my Payment Details'),
    past_tense: i18n.t('Updated my payment details'),
  },
  coverage_change: {
    label: i18n.t('Change my Coverage'),
    past_tense: i18n.t('Changed my coverage'),
  },
  reduce_coverage: {
    label: i18n.t('Reduce my Vehicle Coverage'),
    past_tense: i18n.t('Reduced my Vehicle Coverage'),
  },
  reinstate_coverage: {
    label: i18n.t('Reinstate my Vehicle Coverage'),
    past_tense: i18n.t('Reinstated my Vehicle Coverage'),
  },
  policy_cancel: {
    label: i18n.t('Cancel my Policy'),
    past_tense: i18n.t('Cancelled my Policy'),
  },
  general_change: {
    label: i18n.t('Other'),
    past_tense: i18n.t('Asked a general question'),
  },
}

export const antiTheftDeviceTypes = [
  ['audible', i18n.t('Audible')],
  ['factory', i18n.t('Factory')],
  ['fuel', i18n.t('Fuel Disable')],
  ['ignition', i18n.t('Ignition Disable')],
  ['impact', i18n.t('Impact')],
  ['manual_arming', i18n.t('Manual Arming')],
  ['monitored', i18n.t('Monitored')],
  ['starter_disable', i18n.t('Starter Disable')],
  ['steering_bar', i18n.t('Steering Bar')],
  ['other', i18n.t('Other')]
]

export const antiTheftDeviceManufacturers = [
  ['tag', 'Tag'],
  ['3rd_eye_tracking', '3rd Eye Tracking'],
  ['alpine', 'Alpine'],
  ['apple', 'Apple'],
  ['aprillia', 'Aprillia'],
  ['armed_guard', 'Armed Guard'],
  ['astrolock', 'Astrolock'],
  ['astus', 'Astus'],
  ['audiovox', 'Audiovox'],
  ['auto_gravure', 'Auto Gravure'],
  ['auto_tatou', 'Auto Tatou'],
  ['autoblock', 'Autoblock'],
  ['auto_garde', 'Auto-Garde'],
  ['autographe', 'Autographe'],
  ['autoluck', 'Autoluck'],
  ['automobile_manufacturer', i18n.t('Automobile Manufacturer')],
  ['autotrack', 'Autotrack'],
  ['autowatch_500_rli', 'Autowatch 500 RLi'],
  ['autowatch_500', 'Autowatch 500'],
  ['autowatch', 'Autowatch'],
  ['banshee', 'Banshee'],
  ['bmw', 'BMW'],
  ['boomerang', 'Boomerang'],
  ['boomerang2', 'Boomerang2'],
  ['boomerang_espion', 'Boomerang Espion'],
  ['boomerang_espion_alert', i18n.t('Boomerang Espion Alert')],
  ['boomerang_xpress', 'Boomerang Xpress'],
  ['canam', 'Canam'],
  ['carbine', 'Carbine'],
  ['cellutrak_bikeguard', 'CelluTrak Bikeguard'],
  ['cellutrak_control', 'CelluTrak Control'],
  ['cellutrak_peace', 'CelluTrak Peace'],
  ['cellutrak_recovery', 'CelluTrak Recovery'],
  ['centraxx', 'Centraxx'],
  ['clifford', 'Clifford'],
  ['club', 'Club'],
  ['collard_steadfast', 'Collard Steadfast'],
  ['doc_1000_2000', 'Doc 1000 / 2000'],
  ['doc_x000', 'Doc X000'],
  ['ducati', 'Ducati'],
  ['duro_garde', 'Duro Garde'],
  ['duro_v_i_p', 'Duro V.I.P.'],
  ['econotrack', 'Econotrack'],
  ['espion_lojack', 'Espion - Lojack'],
  ['espion_alert_lojack', i18n.t('Espion Alert - Lojack')],
  ['espion_alert_plus_lojack', i18n.t('Espion Alert Plus - Lojack')],
  ['excel_300', 'Excel 300'],
  ['factory', i18n.t('Factory')],
  ['gemini', 'Gemini'],
  ['gorilla', 'Gorilla'],
  ['guardian', 'Guardian'],
  ['guardian_cadenas', 'Guardian Cadenas'],
  ['harisson_starter', 'Harisson Starter'],
  ['harley_without_siren', i18n.t('Harley without siren')],
  ['harley_davidson', 'Harley-Davidson'],
  ['hawk_100', 'Hawk 100'],
  ['hawk_200', 'Hawk 200'],
  ['hornet', 'Hornet'],
  ['icalock', 'Icalock'],
  ['intercepter', 'Intercepter'],
  ['investiguard', 'Investiguard'],
  ['invisiguard', 'Invisiguard'],
  ['kill', 'Kill'],
  ['kolombo', 'Kolombo'],
  ['ktm', 'KTM'],
  ['larlock', 'Larlock'],
  ['lojack_alert', i18n.t('LoJack Alert')],
  ['lojack_c', 'LoJack C'],
  ['lynx', 'Lynx'],
  ['magtec', 'Magtec'],
  ['mastergard_m6000', 'MasterGard M6000'],
  ['mastergard_tk', 'MasterGard TK'],
  ['merlin', 'Merlin'],
  ['micro_protec', 'Micro-Protec'],
  ['m_link', 'M-link'],
  ['mobicom', 'Mobicom'],
  ['mobiguard', 'Mobiguard'],
  ['mobilus', 'Mobilus'],
  ['mul_t_lock', 'Mul-T-Lock'],
  ['navlynx', 'NavLynx'],
  ['onstar', 'OnStar'],
  ['orca', 'Orca'],
  ['otomax', 'Otomax'],
  ['otoprotec', 'Otoprotec'],
  ['pass_key', 'Pass-Key'],
  ['pfk_autowatch_329_ti', 'PFK Autowatch 329 Ti'],
  ['phantom_x', 'Phantom X'],
  ['powerlock_canada', 'Powerlock-Canada'],
  ['pro_guard', 'Pro-Guard'],
  ['pro_tg', 'PRO-TG'],
  ['pro_tg_3xt', 'PRO-TG 3XT'],
  ['pro_tg_x000', 'Pro-TG X000'],
  ['python', 'Python'],
  ['ramco', 'RAMCO'],
  ['ranger', 'Ranger'],
  ['rlibb', 'RLiBB'],
  ['scorpio', 'Scorpio'],
  ['sherlock', 'Sherlock'],
  ['snyper_mx_7', 'Snyper MX-7'],
  ['spav_06', 'Spav 06'],
  ['spav', 'SPAV'],
  ['spavtrak', 'Spavtrak'],
  ['stealstopper', 'StealStopper'],
  ['super_duro_garde', 'Super Duro-Garde'],
  ['theftbuster_tb300v', 'Theftbuster TB300V'],
  ['tiger_1000', 'Tiger 1000'],
  ['triumph', 'Triumph'],
  ['vigil', 'Vigil'],
  ['vin_lock', 'Vin-Lock'],
  ['viper', 'Viper'],
  ['yamaha', 'Yamaha'],
  ['other', i18n.t('Other')],
  ['none', i18n.t('None')]
]

export const engravingManufacturers = [
  ['tag', 'Tag'],
  ['autograph', 'Autograph'],
  ['autoluck', 'Autoluck'],
  ['automobile_manufacturer', 'Automobile Manufacturer'],
  ['datadot', 'DataDot'],
  ['guardian', 'Guardian'],
  ['larlock', 'Larlock'],
  ['microdot', 'Microdot'],
  ['otoprotec', 'Otoprotec'],
  ['sherlock', 'Sherlock'],
  ['vin_lock', 'Vin Lock'],
  ['other', i18n.t('Other')]
]

export const PO_ADD_DRIVER_VALUE = 1
export const PO_OTHER_VALUE = 0

