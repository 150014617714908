import { useContext } from 'react'
import PreferredContactFields from './PreferredContactFields'
import TextArea from 'forms/fields/TextArea'
import RequestDisclaimer from 'components/RequestDisclaimer'
import { FormContext, AccountContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import ConfirmCard from 'forms/ConfirmCard'

const PreferredContact = () => {
  const { t } = useTranslation()
  const { data } = useContext(FormContext)
  const { contact } = useContext(AccountContext)

  const showComments = () => {
    const type = data ? data['type'] : ''
    if (type === 'payment_schedule' || type === 'payment_date' || type === 'payment_details') {
      return true
    }
    return false
  }

  return (
    <fieldset>
      <PreferredContactFields email={contact.email} />
      {data['credit_or_eft'] === 'eft' &&
        <ConfirmCard title={t("New Payment Details")} slug="update-details" id={data.id}>
          <ul>
            <li>
              <small>{t('Transit Number')}</small>
              {data['transit_num']}
            </li>
            <li>
              <small>{t('Institution Number')}</small>
              {data['institution_num']}
            </li>
            <li>
              <small>{t('Account Number')}</small>
              {data['account_num']}
            </li>
            <li>
              <small>{t('Account Holder(s)')}</small>
              {data['account_holder_name']}
            </li>
          </ul>
        </ConfirmCard>
      }
      {showComments() && (
        <TextArea
          name="billing_comments"
          label={t('If you would like to provide more information, please comment below')}
          HelpCopy={t('Please do not include any personal information such as credit card information or bank account details.')}
          Optional
        />
      )}
      <RequestDisclaimer />
    </fieldset>
  )
}

export default PreferredContact