import { useContext } from 'react'
import { FormContext } from 'utils/context'
import Policies from 'forms/fields/Policies'
import Radio from 'forms/fields/Radio'
import Text from 'forms/fields/Text'
import TextArea from 'forms/fields/TextArea'
import AddressLookup from 'forms/fieldsets/address/AddressLookup'
import { policyCancelReasons } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'
const CancellationDetails = () => {
  const { t } = useTranslation()
  const { data, setFieldValue, clearFieldError } = useContext(FormContext)

  const insuranceCallback = value => {
    if (value === false) {
      setFieldValue('moving_to_carrier', null)
      clearFieldError('moving_to_carrier')
      setFieldValue('moving_to_policy_number', null)
      clearFieldError('moving_to_policy_number')
    }
  }

  const reasonCallback = value => {
    if (value === 'sold_vehicle_or_property') {
      setFieldValue('new_insurance', null)
      clearFieldError('new_insurance')
      insuranceCallback(false)
    }
    if (value === 'other') {
      setFieldValue('reason_for_cancellation_other', null)
      clearFieldError('reason_for_cancellation_other')
    }
  }

  return (
    <fieldset>
      <Policies
        name="cancelling_policies"
        label={t('Which of your policies are you cancelling?')}
        AllowMultiple
        DisplaySingle
      />
      <Radio
        name="reason_for_cancellation"
        label={t('Why are you cancelling your policy?')}
        choices={policyCancelReasons}
        ChangeCallback={reasonCallback}
      />
      {
        data['reason_for_cancellation'] === 'other' &&
        <TextArea
          name="reason_for_cancellation_other"
          label={t('Please provide details.')}
        />
      }
      {
        data['reason_for_cancellation'] !== 'sold_vehicle_or_property' &&
        <Radio
          name="new_insurance"
          label={t('Have you already set up new insurance elsewhere?')}
          choices={[[false, t('No')], [true, t('Yes')]]}
          ChangeCallback={insuranceCallback}
          HelpCopy={data['new_insurance']? t('By providing this information it makes it easier for you to cancel your policy') : ""}
        />
      }
      {data['new_insurance'] && 
        <>
          <Text
            name="moving_to_carrier"
            label={t('Insurance Company')}
          />
          <Text
            name="moving_to_policy_number"
            label={t('Policy Number')}
          />
        </>
      }
      <AddressLookup label={t("Postal code")} help={t("If a refund is owed, your insurance company may send a cheque by mail.")}/>
    </fieldset>
  )
}

export default CancellationDetails