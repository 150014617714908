import i18n from "i18n"

const coverageFields = [
  {
    name: 'coverage_liability',
    label: i18n.t('Liability'),
    choices: [
      ['1000000', i18n.t('$1,000,000')],
      ['2000000', i18n.t('$2,000,000')],
    ],
    help: i18n.t("Third Party Liability can cover costs resulting from injuries to a person or damage to someone else's property with your car.")
  },
  {
    name: 'coverage_coll',
    label: i18n.t('Collision'),
    choices: [
      ['not_included', i18n.t('Not Included')],
      ['500', i18n.t('$500 Deductible')],
      ['1000', i18n.t('$1,000 Deductible')],
    ], 
    help: i18n.t("Collision coverage will repair your vehicle if you are involved in an at-fault accident or single vehicle accident, or hit and run. The deductible is the amount of money that you will pay out of your own pocket before the insurance company responds. As a vehicle gets older, its value goes down, and paying for collision coverage on your car may no longer be worth it. Not purchasing collision coverage may make sense if you are insuring a car that is not worth very much."),
    helpAb: i18n.t("Collision coverage will repair your vehicle if you are involved in an at-fault accident or single vehicle accident. The deductible is the amount of money that you will pay out of your own pocket before the insurance company responds. As a vehicle gets older, its value goes down, and paying for collision coverage on your car may no longer be worth it. Not purchasing collision coverage may make sense if you are insuring a car that is not worth very much.")
  },
  {
    name: 'coverage_comp',
    label: i18n.t('Comprehensive'),
    choices: [
      ['not_included', i18n.t('Not Included')],
      ['500', i18n.t('$500 Deductible')],
      ['1000', i18n.t('$1,000 Deductible')],
    ], 
    help: i18n.t("Comprehensive coverage pays for damages to your vehicle not caused by a traffic accident (e.g. theft, fire, vandalism). As a vehicle gets older, its value goes down, and paying for comprehensive coverage on your car may no longer be worth it. Not purchasing comprehensive coverage may make sense if you are insuring a car that is not worth very much."),
    helpAb: i18n.t("Comprehensive coverage pays for damages to your vehicle not caused by a traffic accident (e.g. theft, fire, vandalism), or a hit and run. As a vehicle gets older, its value goes down, and paying for comprehensive coverage on your car may no longer be worth it. Not purchasing comprehensive coverage may make sense if you are insuring a car that is not worth very much.")
  },
  {
    name: 'endorsement_loss_of_use',
    label: i18n.t('Transportation Replacement'),
    choices: [[false, i18n.t('Not Included')], [true, i18n.t('Included')]], 
    help: i18n.t("If your vehicle is in the shop due to an insured claim, you can be reimbursed up to your limit for a rental car, taxi cab, or whatever you need to get around.")
  },
  {
    name: 'endorsement_non_owned_autos',
    label: i18n.t('Damage to Non-Owned Autos'),
    choices: [[false, i18n.t('Not Included')], [true, i18n.t('Included')]],
    help: i18n.t("If you happen to rent a vehicle anywhere in Canada or the U.S., you do not need to purchase coverage for that rental vehicle, this policy covers any damage up to your limit in Canadian dollars.")
  },
  {
    name: 'endorsement_accident',
    label: i18n.t("Accident Waiver"),
    choices: [[false, i18n.t('Not Included')], [true, i18n.t('Included')]],
    help: i18n.t("In the event of you being in an accident in which you are at fault, it will help protect your driving record and forgive the accident.")
  },
  {
    name: 'endorsement_conviction',
    label: i18n.t('Conviction Protection Endorsement'),
    choices: [[false, i18n.t('Not Included')], [true, i18n.t('Included')]],
    help: i18n.t("Your first minor conviction (such as a speeding ticket) will be forgiven. Which means that your premium will not increase as a result of that minor conviction."),
  },
  {
    name: 'waiver_depreciation',
    label: i18n.t('Waiver of Depreciation'),
    choices: [[false, i18n.t('Not Included')], [true, i18n.t('Included')]],
    help: i18n.t("In case you ever get into an accident which causes the full loss of your vehicle within the first few months you own it, you will be reimbursed for the purchase price, not the depreciated value of the vehicle. This coverage is intended for vehicles purchased new within the last 0-4 years. Please note not all driving records qualify for this coverage."),
    warningTxt: i18n.t("Without this coverage and in the event of a total loss, you will be paid Actual Cash Value with depreciation taken into account.")
  },
  {
    name: 'glass_coverage',
    label: i18n.t('Limited Glass Coverage'),
    choices: [[false, i18n.t('Not Included')], [true, i18n.t('Included')]],
    help: i18n.t("This endorsement will restrict glass coverage to Specified Perils plus vandalism to all windows except the front.")
  },
  {
    name: 'usage_based',
    label: i18n.t('Usage-based Insurance Program'),
    choices: [[false, i18n.t('Not Included')], [true, i18n.t('Included')]],
    help: i18n.t("To qualify, you will need to download the insurance provider's app on your smartphone to capture your driving habits. There is real time feedback through the app for a projected discount, for savings of up to 30% on your policy based on the driving information received. Further discounts (and possible surcharges) vary by insurance provider.")
  },
]

export default coverageFields