import React, { useContext } from 'react'
import { FormContext } from 'utils/context'
import { startOfDay, addDays } from 'date-fns'
import Radio from 'forms/fields/Radio'
import Text from 'forms/fields/Text'
import DateChooser from 'forms/fields/DateChooser'
import { vehicleReplaceReasons } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'

const VehicleReplaceRemoveReason = () => {
  const { data, setFieldValue, clearAllErrors } = useContext(FormContext)
  const { t } = useTranslation()
  
  const reasonChange = value => {
    if (value !== 'other') {
      setFieldValue('remove_reason_other', null)
      clearAllErrors()
    }
  }
  
  let showOtherText = data['remove_reason'] === 'other'
  return (
    <fieldset>
      <Radio
        name="remove_reason"
        label={t("Why are you removing this vehicle?")}
        choices={vehicleReplaceReasons}
        ChangeCallback={reasonChange}
      />
      {showOtherText && (
        <Text name="remove_reason_other" label={t("Other Reason")} />
      )}
      <DateChooser
        name="loss_of_possession_date"
        label={t("When will you no longer have possession of this vehicle?")}
        minDate={startOfDay(new Date())}
        maxDate={addDays(new Date(), 30)}
      />
    </fieldset>
  )
}

export default VehicleReplaceRemoveReason