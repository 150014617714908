import i18n from 'i18n';
import { provinceTiers } from 'forms/licensing/vars'

const errorLookup = (error, province, tenant) => {
  const max_count_licences = 10
  const max_count_suspensions = 10
  const max_count_cancellations = 10
  const max_count_drivers = 4
  const max_count_vehicles = 3
  
  const tiers = provinceTiers[province] || []
  
  const errors = {
    license_dates_general_error: i18n.t('Please check the licence dates entered above and correct any errors'),
    license_dates_training_error: i18n.t('Please add the date the driver received their training certificate'),
    field_required_error: i18n.t('This field is required'),
    other_selected_error: i18n.t('This field is required if you select "other"'),
    invalid_usage_error: i18n.t('Invalid usage type'),
    add_suspension_error: i18n.t('Please select None or add some suspensions'),
    remove_suspension_error: i18n.t('Please select One or More or remove the suspensions'),
    invalid_line_item_error: i18n.t('Invalid line item for user'),
    parent_line_item_submitted_error: i18n.t('Parent line item has already been submitted'),
    max_licences_line_items_error: i18n.t('maxLicencesLineItemsError', {count: max_count_licences}),
    max_suspensions_line_items_error: i18n.t('maxSuspensionLineItemsEerror', {count: max_count_suspensions}),
    suspension_future_date_error: i18n.t('Suspension date cannot be in the future'),
    reinstate_future_date_error: i18n.t('Reinstatement date cannot be in the future'),
    reinstate_before_suspension_error: i18n.t('Reinstatement date cannot be before suspension date'),
    add_driver_error: i18n.t('Please select None or add some other drivers'),
    remove_driver_error: i18n.t('Please select One or More or remove the other drivers'),
    request_not_supported_error: i18n.t('Service request type not supported for this policy'),
    policy_not_found_error: i18n.t('Could not find policy for user'),
    effective_date_past_error: i18n.t('This date cannot be in the past'),
    date_range_error: i18n.t('This date cannot be more than 30 days away'),
    phone_number_error: i18n.t('Invalid phone number'),
    request_type_error: i18n.t('Service request type not supported'),
    max_drivers_error: i18n.t('maxDriversError', {count: max_count_drivers}),
    driver_name_error: i18n.t('An other driver with this name already exists'),
    incomplete_form_error: i18n.t('Please complete the form.'),
    vehicle_year_error: i18n.t('You must set the vehicle year'),
    vehicle_make_error: i18n.t('You must set the vehicle make'),
    vehicle_model_error: i18n.t('You must set the vehicle model'),
    vehicle_vin_error: i18n.t('VIN must be 17 characters and no special characters'),
    vehicle_price_error: i18n.t('You must set the vehicle price'),
    vehcile_condition_error: i18n.t('You must set the vehicle condition'),
    vehcile_km_error: i18n.t('You must set the vehicle kms'),
    max_vehicles_error: i18n.t('maxVehiclesError', {count: max_count_vehicles}),
    vehicle_added_error: i18n.t('You have already added this vehicle for quoting'),
    no_driver_exists_error: i18n.t('Driver does not exist'),
    no_vehicle_exists_error: i18n.t('Vehicle does not exist'),
    full_name_error: i18n.t('Driver missing first and last name'),
    postal_code_error: i18n.t('Invalid postal code'),
    driver_policy_error: i18n.t('Invalid driver for policy'),
    registered_owner_error: i18n.t('Invalid registered owner'),
    driver_error: i18n.t('Invalid driver'),
    add_vehicle_error: i18n.t('You must add between one and three vehicles to quote'),
    invalid_vehicle_error: i18n.t('Invalid vehicle for policy'),
    suspension_not_before_tier_1: i18n.t('Suspension date cannot be before {{license_type}}', {license_type: tiers['tier_1']}),
    suspension_not_before_tier_3: i18n.t('Suspension date cannot be before {{license_type}}', {license_type: tiers['tier_3']}),
    reinstatement_not_before_tier_1: i18n.t('Reinstatement date cannot be before {{license_type}}', {license_type: tiers['tier_1']}),
    reinstatement_not_before_tier_3: i18n.t('Reinstatement date cannot be before {{license_type}}', {license_type: tiers['tier_3']}),
    services_unavilable_error: i18n.t('Some services are temporarily unavailable'),
    password_eight_chars_error: i18n.t('Passwords must be at least eight characters'),
    password_uppercase_char_error: i18n.t('Passwords must contain at least one uppercase character'),
    password_num_error: i18n.t('Passwords must contain at least one number'),
    password_match_error: i18n.t('Passwords do not match'),
    invalid_password_error: i18n.t('Invalid password'),
    activation_error: i18n.t('Invalid activation token'),
    general_error: i18n.t('An error occurred, please contact {{tenant_contact}}', {tenant_contact: tenant.contact_us_url}),
    consent_required_error: i18n.t('Consent required'),
    generic_error_message: i18n.t('An error occurred, please contact us if the problem persists.'),
    payment_delivery_method_error: i18n.t('You must select either mail or email, or both'),
    tag_number_length_error: i18n.t('Tag number must be eight characters'),
    add_cancellation_error: i18n.t('Please select None or add some cancellations'),
    remove_cancellation_error: i18n.t('Please select One or More or remove the cancellation'),
    cancellation_future_date_error: i18n.t('Cancellation date cannot be in the future'),
    max_cancellations_line_items_error: i18n.t('maxCancellationsLineItemsError', {count: max_count_cancellations}),
    reinstate_before_cancellation_error: i18n.t('Reinstatement date cannot be before cancellation date'),
    cancellation_not_before_tier_1: i18n.t('Cancellation date cannot be before {{license_type}}', {license_type: tiers['tier_1']}),
    cancellation_not_before_tier_3: i18n.t('Cancellation date cannot be before {{license_type}}', {license_type: tiers['tier_3']}),
    carrier_requirements_different: i18n.t('Due to different carrier requirements we are unable to process this request, please contact us at {{phone_number}}', {phone_number: tenant.phone_numbers.default.phone}),
    consent_accept: i18n.t('You must accept to continue')
  }
  
  return errors[error]
}

export default errorLookup