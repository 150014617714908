import Button from 'components/Buttons/Button'
import useForm from 'forms/utils/useForm'
import WarningIcon from 'utils/icons/Warning'
import { FormContext } from 'utils/context'
import { postRequest } from 'utils/requests'
import { useTranslation } from 'react-i18next'
import './style.scss'

const SimpleForm = ({ Debug, SubmitLabel, SubmitUrl, SuccessCallback, ErrorCallback, SecondaryButton, ResetTrigger, children, InitialData, AllowCancel }) => {
  
  const { t } = useTranslation()
  const { data, setData, setFieldValue, clearFormData, errors, setFieldError, clearFieldError, clearAllErrors, formError, setFormError, handleFormErrors, formLoading, setFormLoading } = useForm(InitialData)

  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    setFormLoading(true)
    postRequest(SubmitUrl, data)
      .then(res => {
        setFormLoading(false)
        if (InitialData) {
          setData(InitialData)
        }
        if (SuccessCallback) SuccessCallback(res)
      })
      .catch(err => {
        setFormLoading(false)
        console.log('error: ', err)
        if (ErrorCallback) ErrorCallback(err.response, setFormError)
        else handleFormErrors(err.response)
      })
  }

  const handleCancel = e => {
    e.preventDefault()
    clearFormData()
    clearAllErrors()
  }

  return (
    <FormContext.Provider value={{ data, setFieldValue, clearFormData, errors, setFieldError, clearFieldError, clearAllErrors, formError, setFormError, handleFormErrors, formLoading, setFormLoading }}>
      <div className="FormWrapper">
        <form onSubmit={handleSubmit}>
          {children}
          {formError && 
          <div className="form-error">
            <WarningIcon />
            {formError}
          </div>}
          <div className={AllowCancel || SecondaryButton ? "clearfix SubmitButtonAlign" : "clearfix"}>
            <Button DataAttr="simple-form" DataAttrValue="submit" Disabled={Object.keys(errors).length > 0} type="submit" Loading={formLoading} Pill DataTestId="submit">
              {SubmitLabel || t('Submit')}
            </Button>
            {AllowCancel && <Button className="float-right" Pill Transparent onClick={handleCancel}>{t('Cancel')}</Button>}
            {SecondaryButton && SecondaryButton}
          </div>
        </form>
      </div>
    </FormContext.Provider>
  )
}

export default SimpleForm