import { useContext } from 'react'
import MakeARequest from 'components/MakeARequest'
import Masthead from 'components/Masthead'
import NavTrigger from 'components/NavTrigger'
import Button from 'components/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'utils/context'
import Cookies from 'js-cookie'

import './style.scss'

const Header = ({ authed, hasRequest }) => {
  const { i18n, t } = useTranslation()
  const { tenant } = useContext(AppContext)
  
  const languageChanger = () => {
    const newLang = i18n.language === 'en' ? 'fr' : 'en'
    Cookies.set('i18next', newLang)
    window.location.reload();
  }
  
  return (
    <header className={authed ? 'Header Authed' : 'Header'}>
      <div className="container">
        <div className="lngToggle">
          <Masthead />
          {tenant.french_enabled && <Button onClick={() => languageChanger()}>{i18n.language === 'en' ? t('FR') : t('EN')}</Button>}
        </div>
        <MakeARequest hasRequest={hasRequest} />
        <NavTrigger />
      </div>
    </header>
  )
}

export default Header