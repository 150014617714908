import React, { useContext } from 'react'
import { addDays, startOfDay } from 'date-fns'
import Drivers from 'forms/fields/Drivers'
import DateChooser from 'forms/fields/DateChooser'
import { AccountContext } from 'utils/context'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const GetStarted = () => {

  const { t } = useTranslation()
  const { drivers, policies } = useContext(AccountContext)
  const { setFieldValue } = useContext(FormContext)

  const setPolicy = value => {
    const driver = drivers.find(d => d.driver_id === Number(value))
    setFieldValue('policy', driver.policy)
  }

  let policyToDrivers = {}

  policies.forEach(policy => {
    policyToDrivers[policy.id] = []
  })

  drivers.forEach(driver => {
    policyToDrivers[driver.policy].push([driver.driver_id, `${driver.full_name}`]);
  })

  let finalChoices = []

  for (const policyID in policyToDrivers) {
    if (policyToDrivers[policyID].length > 1) {
      policyToDrivers[policyID].forEach(driver => {
        finalChoices.push(driver)
      })
    }
  }

  return (
    <fieldset>
      <Drivers
        name="driver_id"
        label={t('Which driver would you like to remove?')}
        ChangeCallback={setPolicy}
        Stacked
        choices={finalChoices}
      />
      <DateChooser
        minDate={startOfDay(new Date())}
        maxDate={addDays(new Date(), 30)}
        name="effective_date"
        label={t('When would you like this driver removed from your policy?')}
        IncludeYear
      />
      <p>{t("Please note, changes cannot be made outside of 30 days.")}</p>
    </fieldset>
  )
}

export default GetStarted