import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'components/Buttons/Button'
import RestartModal from 'components/RestartModal'
import { AccountContext, AppContext, SupportContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import ChevronUpIcon from 'utils/icons/ChevronUp'
import ChevronDownIcon from 'utils/icons/ChevronDown'

const MultiPageFormNav = ({ id, fs, formData, handleFormErrors, setFieldValue, lastPage, handleSubmit, formLoading }) => {

  const { push } = useHistory()
  const { t } = useTranslation()
  const { setSupportField } = useContext(SupportContext)
  const { setDialogOpen, setPreviouslyFocused } = useContext(AppContext)
  const { currentRequest, updateLineitem, submitting, setSubmitting, policies, setLineitemProvince } = useContext(AccountContext)

  const [isExpanded, setIsExpanded] = useState(false);
  
  const handleSetIsExpanded = (bool) => {
    setIsExpanded(bool)
  }

  const currentIndex = formData.fieldsets && formData.fieldsets.indexOf(formData.current_fieldset)
  const isFinal = formData.current_fieldset === 'confirm' || formData.current_fieldset === 'completed'

  const go = fs => {
    const { requestType, lineitemUrl } = currentRequest
    push(`/account/requests/${requestType}/${lineitemUrl}/${id}/${fs}`)
  }

  const prev = () => {
    let prevFs = formData.fieldsets[currentIndex - 1]
    if (formData.current_fieldset === 'confirm') {
      prevFs = formData.fieldsets[formData.fieldsets.length - 2]
    }
    setFieldValue('current_fieldset', prevFs)
    go(prevFs)
  }

  const setProvince = () => {
    if (formData['policy']) {
      const policy = policies.find(p => Number(p.id) === Number(formData['policy']))
      if (policy && policy.province) {
        setLineitemProvince(policy.province)
      }
    }
  }

  const next = () => {
    setSubmitting(true)
    const nextFs = formData.fieldsets[currentIndex + 1] === 'completed' ? 'confirm' : formData.fieldsets[currentIndex + 1]
    updateLineitem(formData, fs)
      .then(res => {
        setProvince()
        setFieldValue('current_fieldset', nextFs)
        setSubmitting(false)
        go(nextFs)
      })
      .catch(err => {
        handleFormErrors(err.response.data)
        setSubmitting(false)
      })
  }
  
  const save = () => {
    setSubmitting(true)
    updateLineitem(formData, fs)
      .then(res => {
        setProvince()
        setSubmitting(false)
        push('/account/requests')
      })
      .catch(err => {
        handleFormErrors(err.response.data)
        setSubmitting(false)
      })
    setIsExpanded(false)
  }

  const handleHelpClick = e => {
    e.preventDefault()
    setSupportField('')
    setDialogOpen('form-support')
    setPreviouslyFocused('nav-trigger')
    setIsExpanded(false)
  }

  useEffect(() => {
    setFieldValue('current_fieldset', fs)
  }, [fs]) // eslint-disable-line

  return (
    <nav className={`sliding-nav ${isExpanded ? 'expanded' : ''} ${ isExpanded && lastPage ? 'lastpage' : ''} RequestNav`}>
      <div className='container'>
        <div className="main-nav">
          <div>
            {(currentIndex > 0 || isFinal) &&
                <Button onClick={prev} Pill Light className="BackBtn back" DataTestId="back">
                  {t('Back')}
                </Button>
            }
          </div>
          <div className='manage-wrapper'>
            <Button onClick={() => setIsExpanded(!isExpanded)} className='NeedHelp manage' DataTestId="manage">
              <span>{t('More')}&nbsp;</span>
              {isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </Button>
          </div>
          <div className='continue-wrapper'>
            {lastPage ? (
              <Button className='continue' onClick={handleSubmit} Pill Light Loading={submitting || formLoading} DataTestId="submit">
                {t('Submit')}
              </Button>
            ) : (
              <Button className='continue' onClick={next} Pill Light Loading={submitting} DataTestId="continue">
                {t('Continue')}
              </Button>
            )}
          </div>
        </div>

        <div className="expanded-content">
          <div className="expanded-buttons">
            {!lastPage && 
              <Button onClick={save} Pill Light Loading={submitting} DataTestId="Save">
                  {t('Save for later')}
              </Button>
            }
            <RestartModal handleExpanded={handleSetIsExpanded}/>
            <Button onClick={handleHelpClick} Pill Light DataTestId="help">
              {t('Need Help?')}
            </Button>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default MultiPageFormNav