import React, { useContext } from 'react'
import { addDays, startOfDay } from 'date-fns'
import Vehicles from 'forms/fields/Vehicles'
import DateChooser from 'forms/fields/DateChooser'
import { AccountContext } from 'utils/context'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const GetStarted = () => {

  const { t } = useTranslation()
  const { vehicles, policies } = useContext(AccountContext)
  const { setFieldValue } = useContext(FormContext)

  const setPolicy = value => {
    const vehicle = vehicles.find(v => v.vehicle_id === Number(value))
    setFieldValue('policy', vehicle.policy)
  }

  let policyToVehicles = {}

  policies.forEach(policy => {
    policyToVehicles[policy.id] = []
  })

  vehicles.forEach(vehicle => {
    policyToVehicles[vehicle.policy].push([vehicle.vehicle_id, `${vehicle.full_vehicle_name}`]);
  })

  let finalChoices = []

  for (const policyID in policyToVehicles) {
    if (policyToVehicles[policyID].length > 1) {
      policyToVehicles[policyID].forEach(vehicle => {
        finalChoices.push(vehicle)
      })
    }
  }

  return (
    <fieldset>
      <Vehicles
        name="vehicle_id"
        label={t("Which vehicle would you like to remove?")}
        ChangeCallback={setPolicy}
        Stacked
        choices={finalChoices}
      />
      {vehicles.length === 1 && (
        <div className="form-warning">{t('Please note that you are not able to remove a vehicle if it is the only vehicle on a policy. If you are replacing a vehicle, please complete a Vehicle Replace request instead.')}</div>
      )}
      <DateChooser
        minDate={startOfDay(new Date())}
        maxDate={addDays(new Date(), 30)}
        name="effective_date"
        label={t("When will you no longer have possession of the vehicle?")}
        IncludeYear
      />
      <p>{t("Please note, changes cannot be made outside of 30 days.")}</p>
    </fieldset>
  )
}

export default GetStarted