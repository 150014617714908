import './style.scss'
import { useContext, useEffect } from 'react'
import { FormContext } from 'utils/context'
import BOLBankingHelpImage from 'utils/images/BOL_banking_help.png'
import BOLBankingHelpImageFR from 'utils/images/BOL_banking_help_FR.png'
import { useTranslation, Trans } from 'react-i18next'
import Text from 'forms/fields/Text'
import Label from 'forms/fields/Label'
import Select from 'forms/fields/Select'
import Radio from 'forms/fields/Radio'
import { creditOrEFT, ALLOW_CREDIT_OR_EFT, ALLOW_CREDIT_ONLY } from 'forms/utils/vars'
import { AccountContext } from 'utils/context'
import paymentInfo from 'account/MakeAPayment/info'

const DetailsInput = () => {
  
  const { t, i18n } = useTranslation()
  const { data, setFieldValue, clearAllErrors } = useContext(FormContext)
  const { policies } = useContext(AccountContext)
  
  const getCarrierStrList = () => {
    const carrierCodes = []
    data['requested_policies'].forEach((id) => {
      const policy = policies.find(policy => id === policy.policy_id)
      if (policy && !carrierCodes.includes(policy.carrier)) {
        carrierCodes.push(policy.carrier)
      }
    })
    
    if (carrierCodes.length === 0) return ''
    if (carrierCodes.length === 1) return carrierCodes[0]
    
    const lastCarrier = carrierCodes[carrierCodes.length - 1]
    const otherCarriers = carrierCodes.slice(0, -1)
    
    return otherCarriers.join(', ') + ` ${t('and')} ` + lastCarrier
  }
  
  const getCarrierCodeNameList = () => {
    const carrierCodes = {}
    data['requested_policies'].forEach((id) => {
      const policy = policies.find(policy => id === policy.policy_id)
      if (policy && !(policy.carrier_code in carrierCodes)) {
        carrierCodes[policy.carrier_code] = policy.carrier
      }
    })
    
    return carrierCodes
  }
  
  const hasMatchingKeys = (allowedCarrCodes) => {
    const carrierCodes = getCarrierCodeNameList()
    return Object.keys(carrierCodes).some(key => allowedCarrCodes.includes(key))
  }
        
  const inputEFT = () => {
    const carrierList = getCarrierStrList()
    
    return (
      <div>
        <Label label={t('Please provide your updated payment details.')} name="details-laebl" />
        <Text 
          name="transit_num"
          label={t('New Transit Number')}
          type="number"
        />
        <Text 
          name="institution_num"
          label={t('New Institution Number')}
          type="number"
        />
        <Text 
          name="account_num"
          label={t('New Account Number')}
          type="number"
        />
        <Text 
          name="account_holder_name"
          label={t('Name(s) of the Account Holder(s)')}
        />
        <img alt={t('Void Cheque')} src={i18n.language === 'fr' ? BOLBankingHelpImageFR : BOLBankingHelpImage} />
        <br />
        <p className="fieldset-intro">
          <small>{t('You hereby confirm understanding of the terms and conditions of the payment plan being provided to you. You acknowledge that all persons on the account are providing authorization for automatic withdrawals. You hereby authorize the following named bank or any other financial institution which you may identify, to debit your account each month for the purpose of paying insurance premiums to {{carrier_name}}.', {carrier_name: carrierList, interpolation: { escapeValue: false }})}</small>
          <br />
          <small>{t('You will ensure that funds are available to cover the amount of the withdrawal and understand that non-sufficient funds transactions may result in additional fees or in cancellation of your policy. In the event of a change in premium due to coverage, rate, or upon renewal, you understand that the payment amount will be automatically changed. You do have the right to receive reimbursement for any debit that is not authorized by this agreement.')}</small>
          <br />
          <small>
            <Trans 
              i18nKey="consentFinal"
              values={{carrier_name: carrierList , interpolation: { escapeValue: false }}}
              components={{ a: <a href={i18n.language === 'fr' ? 'https://www.paiements.ca/' : 'https://www.payments.ca/'} target='_blank' rel='noreferrer'/>}} //eslint-disable-line
            />
          </small>
        </p>
        <Radio
          name="consent"
          label={t('Do you accept?')}
          choices={[
            [true, t('Yes, I accept')],
            [false, t('No, I do not accept')],
          ]}
          Stacked
        />
      </div>
    )
  }
  
  const updateCredit = () => {
    const carrierCodes = getCarrierCodeNameList()
    return (
      <div>
        <span>
          {t('Please do not provide your updated credit card details on this site. Submit this form and we will be in contact to collect updated details.')}
          {Object.keys(carrierCodes).map((key, index) => {
            const info = paymentInfo[key]
            if(info && info.link){
              return (
                <div key={index}>
                  <br/>
                  <Trans 
                    i18nKey="creditCardPayment"
                    values={{carrier_name: carrierCodes[key]}}
                    components={{ a: <a href={info.link} target='_blank' rel='noreferrer' className='underline-link'/>}} //eslint-disable-line
                  />
                </div>
              )
            } 
            else {
              return ""
            }
          })}
        </span>
      </div>
    )
  }
  
  const creditAndEft = () => {
    const creditOrEftCallback = (value, name) => {
      if (value !== 'credit'){
        setFieldValue('transit_num', null)
        setFieldValue('institution_num', null)
        setFieldValue('account_num', null)
        setFieldValue('account_holder_name', null)
        setFieldValue('consent', null)
        clearAllErrors()
      }
    }
    
    return (
      <div>
        <Select
          name="credit_or_eft"
          label={t("What payment information are you looking to update?")}
          choices={creditOrEFT}
          ChangeCallback={creditOrEftCallback}
        />
        {data['credit_or_eft'] === 'eft' && inputEFT()}
        {data['credit_or_eft'] === 'credit' && updateCredit()}
      </div>
    )
  }
  
  useEffect(() => {
    if(hasMatchingKeys(ALLOW_CREDIT_ONLY)){
      setFieldValue('credit_or_eft', 'credit')
    }else if(!hasMatchingKeys(ALLOW_CREDIT_OR_EFT)){
      setFieldValue('credit_or_eft', 'eft')
    }
  }, []) //eslint-disable-line

  return (
    <fieldset>
      {hasMatchingKeys(ALLOW_CREDIT_OR_EFT) ? creditAndEft() : hasMatchingKeys(ALLOW_CREDIT_ONLY) ? updateCredit() : inputEFT()}
    </fieldset>
  )
}

export default DetailsInput