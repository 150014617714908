import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import useField from 'forms/utils/useField'
import Label from './Label'
import FieldError from './FieldError'
import TenantComponent from 'components/TenantComponent'
import { AccountContext } from 'utils/context'
import AutoIcon from 'utils/icons/Auto'
import PropertyIcon from 'utils/icons/Property'
import { useTranslation } from 'react-i18next'

const Policies = ({ name, label, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, AutoOnly, AllowMultiple, DisplaySingle, disabledPolicies }) => {

  const { t } = useTranslation()
  const { policies, vehicles } = useContext(AccountContext)

  const [single, setSingle] = useState(false)

  const { value, onChange, onFocus, onBlur, active, touched, error } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback)

  let pols = policies

  if (AutoOnly) {
    pols = policies.filter(p => p.lob === 'auto')
  }

  const arrayValue = (typeof value === 'object' && value) || []

  const handleChange = e => {
    const num = e.target.name
    let next = [...arrayValue]
    if (next.indexOf(num) === -1) {
      next.push(num)
    } else {
      next = next.filter(p => p !== num)
    }
    onChange({ target: { value: next } })
  }

  const handleChangeSingle = id => {
    onChange({ target: { value: id } })
  }

  const containsPolicy = num => {
    const pols = arrayValue.filter(p => p === String(num))
    return pols.length > 0
  }

  const isPolicyId = id => {
    return value === id
  }

  useEffect(() => {
    if (pols.length === 1 && !single) {
      setSingle(true)
      const val = AllowMultiple ? [pols[0].policy_id] : pols[0].policy_id
      onChange({ target: { value: val } })
    }
  }, [onChange, pols, single, AllowMultiple])

  const policyVehicles = polNumber => {
    const pol = policies.find(p => p.policy_number === polNumber)
    const policyVehicles = vehicles.filter(v => v.policy === pol.id)
    return policyVehicles.length > 0 ? (
      <small>
        {policyVehicles.map((v, i) =>
          <div key={i}>{v.full_vehicle_name}</div>
        )}
      </small>
    ) : null
  }

  const cls = classNames('field radio policies stacked', {
    active: active,
    touched: touched,
    'has-error': error,
    'single-policy': single,
    'hidden': single && !DisplaySingle,
  })

  return (
    <div id={`${name}-field`} className={cls} data-testid={name}>
      <Label label={label} name={name} />
      <div className="field-wrapper">
        <div className="choices">
          {pols.map(p => (
            <Label key={p.id} secondaryLabel className={containsPolicy(p.policy_id) || isPolicyId(p.id) ? 'checked clearfix ': 'clearfix ' + (disabledPolicies && disabledPolicies.includes(p.carrier_code) ? 'disabled' : '')} DataTestId={`${name}--${p.id}`}>
              <div className="PolicyIcon">
                {p.lob === 'auto' ? <TenantComponent element={<AutoIcon />} elementName="AutoIcon" /> : <TenantComponent element={<PropertyIcon />} elementName="PropertyIcon" />
                }
              </div>
              <input
                name={p.policy_id}
                onChange={AllowMultiple ? handleChange : () => handleChangeSingle(p.id)}
                onFocus={onFocus}
                onBlur={onBlur}
                type="checkbox"
                disabled={disabledPolicies && disabledPolicies.includes(p.carrier_code)}
              />
              <div className="PolicyDetails">
                <strong>{p.policy_number} <span className="CheckedIndicator" /></strong>
                {policyVehicles(p.policy_number)}
                {disabledPolicies && disabledPolicies.includes(p.carrier_code) && <small>{t('At this time {{carrier_name}} does not allow the changing of payment dates.', {carrier_name: p.carrier})}</small>}
              </div>
            </Label>
          ))}
        </div>
      </div>
      <FieldError error={error} name={name} />
    </div>
  )
}

export default Policies